

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Passion+One:400,700,900');

/*1. css reset */

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}


/* 1.1 HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1.5;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
}

ol,
ul {
    list-style: none;
}
.main {
    border-radius: 32px;
    box-shadow: inset 1px 10px 1px 1px #ff0a78;

}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

a,
input,
button,
select,
textarea {
    outline: none;
}

::-webkit-input-placeholder {
    color: #737373;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #737373;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #737373;
}

:-ms-input-placeholder {
    color: #737373;
}

a {
    outline: none;
    text-decoration: none;
    color: #201f1f;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
}

a:hover,
a:active,
a:focus {
    text-decoration: none;
    color: #ff0a78;
}


/* 1.2 Flaticon Fonts */

@font-face {
    font-family: "Flaticon";
    src: url("../webkit/Flaticon.eot");
    src: url("../webkit/Flaticon.eot?#iefix") format("embedded-opentype"), url("../webkit/Flaticon.woff") format("woff"), url("../webkit/Flaticon.ttf") format("truetype"), url("../webkit/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../webkit/Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 20px;
    font-style: normal;
}

.flaticon-arm:before {
    content: "\f100";
}

.flaticon-athletic-strap:before {
    content: "\f101";
}

.flaticon-balance:before {
    content: "\f102";
}

.flaticon-barbell:before {
    content: "\f103";
}

.flaticon-barbell-1:before {
    content: "\f104";
}

.flaticon-barbell-2:before {
    content: "\f105";
}

.flaticon-basketball-jersey:before {
    content: "\f106";
}

.flaticon-bench:before {
    content: "\f107";
}

.flaticon-blender:before {
    content: "\f108";
}

.flaticon-bosu-ball:before {
    content: "\f109";
}

.flaticon-boxing-mannequin:before {
    content: "\f10a";
}

.flaticon-boxing-shorts:before {
    content: "\f10b";
}

.flaticon-brawn:before {
    content: "\f10c";
}

.flaticon-buck:before {
    content: "\f10d";
}

.flaticon-cancel:before {
    content: "\f10e";
}

.flaticon-centimeter:before {
    content: "\f10f";
}

.flaticon-chest:before {
    content: "\f110";
}

.flaticon-chest-1:before {
    content: "\f111";
}

.flaticon-chest-expander:before {
    content: "\f112";
}

.flaticon-dressing-room:before {
    content: "\f113";
}

.flaticon-drug:before {
    content: "\f114";
}

.flaticon-dumbbell:before {
    content: "\f115";
}

.flaticon-dumbbell-1:before {
    content: "\f116";
}

.flaticon-dumbbell-2:before {
    content: "\f117";
}

.flaticon-dumbbell-3:before {
    content: "\f118";
}

.flaticon-dumbbell-4:before {
    content: "\f119";
}

.flaticon-dumbbell-5:before {
    content: "\f11a";
}

.flaticon-exercise-bands:before {
    content: "\f11b";
}

.flaticon-exercise-bike:before {
    content: "\f11c";
}

.flaticon-facebook:before {
    content: "\f11d";
}

.flaticon-fitness:before {
    content: "\f11e";
}

.flaticon-fitness-1:before {
    content: "\f11f";
}

.flaticon-fitness-2:before {
    content: "\f120";
}

.flaticon-fitness-bracelet:before {
    content: "\f121";
}

.flaticon-fresh-juice:before {
    content: "\f122";
}

.flaticon-gloves:before {
    content: "\f123";
}

.flaticon-google-plus:before {
    content: "\f124";
}

.flaticon-grip:before {
    content: "\f125";
}

.flaticon-gym:before {
    content: "\f126";
}

.flaticon-gymnast-mat:before {
    content: "\f127";
}

.flaticon-gymnast-rings:before {
    content: "\f128";
}

.flaticon-gymnastic-ball:before {
    content: "\f129";
}

.flaticon-gymnastic-roller:before {
    content: "\f12a";
}

.flaticon-hand-grip:before {
    content: "\f12b";
}

.flaticon-headgear:before {
    content: "\f12c";
}

.flaticon-heart:before {
    content: "\f12d";
}

.flaticon-heart-1:before {
    content: "\f12e";
}

.flaticon-horizontal-bar:before {
    content: "\f12f";
}

.flaticon-hydratation:before {
    content: "\f130";
}

.flaticon-instagram:before {
    content: "\f131";
}

.flaticon-iron-shoots:before {
    content: "\f132";
}

.flaticon-jumping-rope:before {
    content: "\f133";
}

.flaticon-leg:before {
    content: "\f134";
}

.flaticon-linkedin:before {
    content: "\f135";
}

.flaticon-list:before {
    content: "\f136";
}

.flaticon-lungs:before {
    content: "\f137";
}

.flaticon-medicine-ball:before {
    content: "\f138";
}

.flaticon-music-player:before {
    content: "\f139";
}

.flaticon-no-drugs:before {
    content: "\f13a";
}

.flaticon-no-fast-food:before {
    content: "\f13b";
}

.flaticon-phytonutrients:before {
    content: "\f13c";
}

.flaticon-pills:before {
    content: "\f13d";
}

.flaticon-pills-1:before {
    content: "\f13e";
}

.flaticon-pinterest:before {
    content: "\f13f";
}

.flaticon-plastic-bottle:before {
    content: "\f140";
}

.flaticon-press-simulator:before {
    content: "\f141";
}

.flaticon-punching-bag:before {
    content: "\f142";
}

.flaticon-punching-bag-1:before {
    content: "\f143";
}

.flaticon-punching-bag-2:before {
    content: "\f144";
}

.flaticon-punching-ball:before {
    content: "\f145";
}

.flaticon-punching-ball-1:before {
    content: "\f146";
}

.flaticon-rings:before {
    content: "\f147";
}

.flaticon-scale:before {
    content: "\f148";
}

.flaticon-scales:before {
    content: "\f149";
}

.flaticon-share:before {
    content: "\f14a";
}

.flaticon-shoe:before {
    content: "\f14b";
}

.flaticon-shopping-cart:before {
    content: "\f14c";
}

.flaticon-shorts:before {
    content: "\f14d";
}

.flaticon-shower:before {
    content: "\f14e";
}

.flaticon-shower-1:before {
    content: "\f14f";
}

.flaticon-skipping-rope:before {
    content: "\f150";
}

.flaticon-sport-bag:before {
    content: "\f151";
}

.flaticon-sports-shirt:before {
    content: "\f152";
}

.flaticon-sportswear:before {
    content: "\f153";
}

.flaticon-stairs:before {
    content: "\f154";
}

.flaticon-standing-punching-ball:before {
    content: "\f155";
}

.flaticon-star:before {
    content: "\f156";
}

.flaticon-step:before {
    content: "\f157";
}

.flaticon-step-platform:before {
    content: "\f158";
}

.flaticon-stopwatch:before {
    content: "\f159";
}

.flaticon-stopwatch-1:before {
    content: "\f15a";
}

.flaticon-stretching-punching-ball:before {
    content: "\f15b";
}

.flaticon-telegram:before {
    content: "\f15c";
}

.flaticon-trainer-rod:before {
    content: "\f15d";
}

.flaticon-treadmill:before {
    content: "\f15e";
}

.flaticon-treadmill-1:before {
    content: "\f15f";
}

.flaticon-treadmill-2:before {
    content: "\f160";
}

.flaticon-treadmill-3:before {
    content: "\f161";
}

.flaticon-trellis:before {
    content: "\f162";
}

.flaticon-twitter:before {
    content: "\f163";
}

.flaticon-waist:before {
    content: "\f164";
}

.flaticon-watch:before {
    content: "\f165";
}

.flaticon-water-bottle:before {
    content: "\f166";
}

.flaticon-weight:before {
    content: "\f167";
}

.flaticon-weight-1:before {
    content: "\f168";
}

.flaticon-weight-2:before {
    content: "\f169";
}

.flaticon-weight-3:before {
    content: "\f16a";
}

.flaticon-weight-4:before {
    content: "\f16b";
}

.flaticon-weight-bar:before {
    content: "\f16c";
}

.flaticon-weights:before {
    content: "\f16d";
}

.flaticon-wheel:before {
    content: "\f16e";
}

.flaticon-whistle:before {
    content: "\f16f";
}

.flaticon-workout:before {
    content: "\f170";
}

.flaticon-youtube:before {
    content: "\f171";
}

.fIcons:before {
    font-size: 72px;
    color: #ff0a78;
}

.fIcons {
    height: 72px;
    display: inline-block;
    line-height: 72px;
}


/* 1.3 css reset */

.clear {
    clear: both;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

img {
    max-width: 100%;
    vertical-align: top;
}


/* 2. Global CSS */

body {
    margin: 0;
    font-size: 14px;
    color: #201f1f;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    word-wrap: break-word;
    font-family: 'Open Sans', sans-serif;
    /*font-family: 'Passion One', cursive;*/
}

h1 {
    font-size: 130px;
    line-height: normal;
}

h2 {
    font-size: 70px;
    line-height: 75px;
}

h3 {
    font-size: 30px;
    line-height: 40px;
}

h4 {
    font-size: 24px;
    line-height: 30px;
}

h5 {
    font-size: 16px;
    line-height: 27px;
}

h6 {
    font-size: 15px;
    line-height: 27px;
}

.mar-top-120 {
    margin-top: 120px;
}

.mar-top-110 {
    margin-top: 110px;
}

.mar-bottom-70 {
    margin-bottom: 70px;
}

.pad-top-120 {
    padding-top: 120px;
}

.pad-bottom-120 {
    padding-bottom: 120px;
}

.pad-top-115 {
    padding-top: 115px;
}

.pad-bottom-115 {
    padding-bottom: 115px;
}

.pad-top-110 {
    padding-top: 110px;
}

.pad-bottom-110 {
    padding-bottom: 110px;
}

.pad-top-60 {
    padding-top: 60px;
}

.pad-bottom-60 {
    padding-bottom: 60px;
}

.pad-top-55 {
    padding-top: 55px;
}

.no-padding {
    padding: 0;
}

section {
    overflow: hidden;
}

.parallax {
    background-attachment: fixed !important;
    background-size: cover !important;
}

p {
    font-size: 18px;
    line-height: 28px;
}

.pr {
    position: relative;
}

.creamBg {
    background-color: #ededef;
}

.titleTop {
    color: #fbfbfb;;
    font-weight: 600;
    margin-left: 23px;
    line-height: 20px;
    text-transform: uppercase;
}

.sectionTitle {
    text-transform: uppercase;
    font-style: italic;
    font-family: 'Passion One', cursive;
    margin-bottom: 42px;
}

.sectionTitle span {
    color: #ff0a78;
}

::-moz-selection {
    color: #fff;
    background: #ff0a78;
}

::selection {
    color: #fff;
    background: #ff0a78;
}

@media (min-width: 1400px) {
    .container {
        width: 1338px;
    }
}


/* 3. Site Head CSS */

#site-head {
    display: inline-block;
    height: 768px;
    height: 100vh;
    min-height: 800px;
    margin: 0px 1px 17px;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
    padding: 0;
    overflow: hidden;
    width: 100%;
    position: relative;
    vertical-align: top;
}

.top-head ul {
    display: inline-block;
    padding-right: 15px;
}

.top-head ul li,
.top-head ul li a {
    float: left;
    color: #fff;
    font-size: 14px;
    font-family: 'Passion One', cursive;
    font-style: italic;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
}

.top-head ul li a:hover {
    color: #ff0a78;
}

.top-head ul li {
    padding: 15.5px 24px;
    position: relative;
}

.top-head ul li:after {
    content: "\f111";
    display: inline-block;
    font-family: 'FontAwesome';
    position: absolute;
    top: 22px;
    right: 0;
    width: auto;
    height: auto;
    font-style: normal;
    font-size: 4px;
}

.top-head ul li:last-child:after {
    content: "";
}

.top-head ul li i {
    margin-right: 5px;
}

#site-head:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    min-height: 800px;
    background-color: rgba(0, 0, 0, 0.30);
    content: "";
}

#site-head:after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom: 100px solid #ff0a78;
    border-left: 430px solid transparent;
}

.logo .navbar-brand {
    padding: 45px 0px 45px 48px;
    margin: 0 78px 0 0 !important;
    height: auto;
    line-height: normal;
    background-color: #ff0a78;
    position: relative;
    z-index: 1;
}

.logo {
    position: absolute;
    left: 0;
    top: -23px;
    z-index: 1;
}

.menuBar .navbar-default {
    position: static;
}

.logo:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ff0a78;
    transform: skew(-43deg);
    -webkit-transform: skew(-43deg);
    -moz-transform: skew(-43deg);
    -ms-transform: skew(-43deg);
    box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 1);
}

.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
    background-color: #ff0a78;
}

.menuBar .navbar-default {
    background-color: transparent;
    border: 0;
    margin: 0;
}

.menuBar .navbar-collapse {
    border: 0;
    background-color: transparent;
    position: relative;
    right: 50px;
}

.menuBar .skewbox {
    position: absolute;
    top: 0;
    left: auto;
    right: 80px;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: skew(-40deg);
    -webkit-transform: skew(-40deg);
    -moz-transform: skew(-40deg);
    -ms-transform: skew(-40deg);
}

.menuBar .navbar-right {
    margin: 0;
    padding-right: 15px;
}

.menuBar ul li a {
    padding: 54px 25px;
}

.menuBar .navbar-default .navbar-nav>.active>a,
.menuBar .navbar-default .navbar-nav>.active>a:focus,
.menuBar .navbar-default .navbar-nav>.active>a:hover,
.menuBar .navbar-default .navbar-nav>li>a:focus,
.menuBar .navbar-default .navbar-nav>li>a:hover {
    color: #ff0883;
    background-color: transparent;
}

.menuBar .navbar-default .navbar-nav>li>a {
    color: #201f1f;
    font-size: 16px;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
}

.logo img {
    max-width: 270px;
    width: 100%;
}

header.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
    background-color: #fff;
    overflow: hidden;
}

header.fixed.headClr {
    overflow: visible;
}

header.fixed .logo {
    top: 0;
}

header.fixed .logo .navbar-brand {
    margin: 0 !important;
    padding: 9px 0px 10px 30px;
    height: 100%;
    width: 80%;
}

header.fixed .logo:after {
    box-shadow: none;
}

header.fixed .menuBar .skewbox {
    transform: skew(0deg);
    -webkit-transform: skew(0deg);
    -moz-transform: skew(0deg);
    -ms-transform: skew(0deg);
}

header.fixed .menuBar .navbar-collapse {
    right: 0;
    background-color: #fff;
}

header.fixed .menuBar ul li a {
    padding: 30px 25px;
}

header.fixed .menuBar .navbar-right {
    padding-right: 0;
}


/* 4. Banner CSS */

.banner {
    /* padding-top: 220px; */
    position: relative;
    margin-top: 230px;
    overflow: hidden;
}

.banner h1 {
    text-transform: uppercase;
    color: #ffe402;
    font-style: italic;
    font-family: 'Passion One', cursive;
    line-height: 122px;
}

.banner .slideout {
    position: relative;
    left: 66px;
}

.banner h3 {
    color: #fff;
    letter-spacing: 15.3px;
    position: relative;
    left: 45px;
    text-transform: uppercase;
}

.bannerText {
    position: relative;
    left: -50px;
}

.banskew {
    display: inline-block;
    width: 100%;
    height: 285px;
    position: absolute;
    top: 16px;
    right: 100%;
    background-color: #ff0a78;
    transform: skew(-17.9deg);
    -webkit-transform: skew(-17.9deg);
    -moz-transform: skew(-17.9deg);
    -ms-transform: skew(-17.9deg);
    box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.5);
    margin-right: 7.5%;
}

.scrollDown h6 {
    color: #ffffff;
    display: inline-block;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    animation: bounce 2s infinite ease-in-out;
    -webkit-animation: bounce 2s infinite ease-in-out;
    -moz-animation: bounce 2s infinite ease-in-out;
    -o-animation: bounce 2s infinite ease-in-out;
}

.scrollDown h6 a {
    color: #ffffff;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    transition: 0.3s all;
}

.scrollDown {
    position: relative;
    z-index: 1;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    display: inline-block;
    position: absolute;
    bottom: 100px;
    right: 0;
    overflow: visible;
}

@-webkit-keyframes bounce {
    0%,
    100% {
        -webkit-transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(-20px);
    }
}

@-moz-keyframes bounce {
    0%,
    100% {
        -webkit-transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(-20px);
    }
}

@-o-keyframes bounce {
    0%,
    100% {
        -webkit-transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(-20px);
    }
}

@keyframes bounce {
    0%,
    100% {
        -webkit-transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(-20px);
    }
}


/* 5. Boxes CSS */

.box {
    position: relative;
}

.boxInfo {
    position: absolute;
    bottom: 30px;
    right: 30px;
}

.box.box-1 {
    background-color: #ffe402;
}

.box.box-2 {
    background-color: #fa4b06;
}

.box.box-3 {
    background-color: #5e0bf6;
}

.box figure img {
    opacity: 0.4;
}

.boxInfo h3 {
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
    font-family: 'Passion One', cursive;
    position: relative;
}

.boxInfo h3:after {
    content: "";
    display: block;
    width: 50px;
    height: 3px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: skew(-40deg);
    -webkit-transform: skew(-40deg);
    -moz-transform: skew(-40deg);
    -ms-transform: skew(-40deg);
}


/* 6. About CSS */

#about {
    position: relative;
}

.about p {
    color: #fbfbfb;
    margin-bottom: 35px;
}

.about p:last-child {
    margin-bottom: 50px;
}

.about .titleTop {
    margin-left: 12px;
}


/* 7. Feature CSS */

#feature {

}

#feature .titleTop {
    color: #fff;
}

.feature h4 {
    color: #ff0a78;
    text-transform: uppercase;
    font-style: italic;
    font-family: 'Passion One', cursive;
    margin-top: 30px;
    margin-bottom: 18px;
}

.feature p {
    color: #fff;
    font-size: 16px;
}

.feBox {
    margin-bottom: 70px;
}

.feBox:last-child,
.feBox:nth-last-child(2) {
    margin-bottom: 0;
}

.skewpink {
    position: absolute;
    right: 100%;
    top: 5px;
    height: 73px;
    width: 100%;
    background-color: #ff0a78;
    transform: skew(-29.6deg);
    -webkit-transform: skew(-29.6deg);
    -moz-transform: skew(-29.6deg);
    -ms-transform: skew(-29.6deg);
    -webkit-transform-origin: 0% 50%;
    -moz-transform-origin: 0% 50%;
    -o-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    z-index: 1;
    margin-right: 15px;
}


/* 8. Class css */

.class {
    position: relative;
}

.button-group {
    background-color: #ffe402;
    display: inline-block;
    position: relative;
    transform: skew(-29.6deg);
    -webkit-transform: skew(-29.6deg);
    -moz-transform: skew(-29.6deg);
    -o-transform: skew(-29.6deg);
}

.button-group .button {
    border: 0;
    background-color: transparent;
    font-size: 16px;
    color: #201f1f;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    padding: 23px 38px;
    position: relative;
}

.button-group .button.is-checked {
    color: #fff;
    z-index: 1;
}

.button-group .button span {
    display: block;
    transform: skew(29.6deg);
    -webkit-transform: skew(29.6deg);
    -moz-transform: skew(29.6deg);
    -o-transform: skew(29.6deg);
}

.button-group .button:last-child::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ff0a78;
    content: '';
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    box-shadow: 10px 10px 35px -1px rgba(0, 0, 0, 0.3);
}


/* Move the bg */

.button-group .button:first-child.is-checked ~ .button:last-child::before {
    -webkit-transform: translate3d(-652%, 0, 0);
    transform: translate3d(-650%, 0, 0);
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (min-color-index:0) { 

    .button-group .button:first-child.is-checked ~ .button:last-child::before {
        -webkit-transform: translate3d(-652%, 0, 0);
        transform: translate3d(-650%, 0, 0);
    }

    /* Safari only override */
    ::i-block-chrome, .button-group .button:first-child.is-checked ~ .button:last-child::before {
        -webkit-transform: translate3d(-652%, 0, 0);
        transform: translate3d(-650%, 0, 0);
    }
}

.button-group .button:nth-child(2).is-checked ~ .button:last-child::before {
    -webkit-transform: translate3d(-532%, 0, 0);
    transform: translate3d(-532%, 0, 0);
}

.button-group .button:nth-child(3).is-checked ~ .button:last-child::before {
    -webkit-transform: translate3d(-420%, 0, 0);
    transform: translate3d(-420%, 0, 0);
}

.button-group .button:nth-child(4).is-checked ~.button:last-child::before {
    -webkit-transform: translate3d(-314%, 0, 0);
    transform: translate3d(-314%, 0, 0);
}

.button-group .button:nth-child(5).is-checked ~ .button:last-child::before {
    -webkit-transform: translate3d(-206%, 0, 0);
    transform: translate3d(-206%, 0, 0);
}

.button-group .button:nth-child(6).is-checked ~.button:last-child::before {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.class .transition {
    margin-top: 85px;
}

.element {
    background-color: #fff;
    text-align: center;
    position: relative;
    padding: 0 30px 47px;
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.blckbox {
    position: absolute;
    left: 0;
    top: -2px;
    background-color: #201f1f;
    /*-webkit-transform-origin: 0% 50%;
    -moz-transform-origin: 0% 50%;
    -o-transform-origin: 0% 50%;
    transform-origin: 0% 50%;*/
    box-shadow: 10px 10px 20px -2px rgba(0, 0, 0, 0.25);
    z-index: 111;
}

.blckbox:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 5px;
    width:100%;
    height: 100%;
    background-color: #201f1f;
    -webkit-transform: skew(-10.6deg);
    -o-transform: skew(-10.6deg);
    -moz-transform: skew(-10.6deg);
    transform: skew(-10.6deg);
    z-index: -1;
}
.tttrtt {
    padding: 0px 0px 0px 4px;
    font-size: 12px;
    text-transform: uppercase;
    color: #ffe402 !important;
    font-weight: 700;
    letter-spacing: 0.72px;
    transform-origin: 0%;
    line-height: normal;
}
.blckbox h6 {
    padding: 0px 0px 0px 4px;
    font-size: 40px;
    text-transform: uppercase;
    color: #ffe402;
    font-weight: 700;
    letter-spacing: 0.72px;
    /*-webkit-transform: perspective(300px) rotateX(29.6deg);
    -o-transform: perspective(300px) rotateX(29.6deg);
    -moz-transform: perspective(300px) rotateX(29.6deg);*/
    -webkit-transform-origin: 0%;
    -moz-transform-origin: 0%;
    -o-transform-origin: 0%;
    transform-origin: 0%;
    line-height: normal;
}

.blckbox h6 span {
    display: none;
}

.products .blckbox {
    width: 62px;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    transition: width 0.3s;
    text-align: left;
}

.products .element:hover .blckbox {
    width: 94px;
}

.products .blckbox h6 span {
    position: absolute;
    right: 15px;
    top: 14px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.products .element:hover .blckbox h6 span {
    display: inline-block;
}

.element .fIcons {
    margin: 64px 0 25px;
    height: 110px;
    line-height: 110px;
}

.element .fIcons:before {
    font-size: 110px;
}

.element h4 {
    color: #201f1f;
    font-weight: 900;
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 30px;
}

.element p {
    color: #737373;
    font-style: italic;
}

.element-btn {
    float: left;
    clear: both;
    margin-top: 20px;    
    width: 100%;
}

.element-btn .element-fill-btn {
    background-color: #ff0a78;
    border: 0;
    color: #fff;
    padding: 13px 5px;
    font-size: 16px;
    font-weight: 900;
    font-style: italic;
    text-transform: uppercase;
    width: 252px;
    margin: 0 auto;
    border-radius: 25px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 50px -10px rgba(255, 10, 120, 1);
   
}

.fill-btn {
    background-color: #ff0a78;
    border: 0;
    color: #fff;
    padding: 13px 5px;
    font-size: 16px;
    font-weight: 900;
    font-style: italic;
    text-transform: uppercase;
    width: 252px;
    margin: 0 auto;
    border-radius: 25px;
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 50px -10px rgba(255, 10, 120, 1);
}

.fill-btn:hover,
.element-btn .element-fill-btn:hover {
    background-color: #201f1f;
    box-shadow: 0px 10px 50px -10px rgb(32, 31, 31);
}


/* 9. Gallery css */

.gallery {
    position: relative;
    background-image: url('http://placehold.it/1920x1527');
    background-repeat: no-repeat;
    padding-bottom: 85px;
}

.gallery:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
}

.gallery .titleTop,
.gallery .sectionTitle {
    color: #fff;
    margin-bottom: 0;
}

.gallery-button-group button {
    font-size: 32px;
    color: #ffffff;
    background-color: transparent;
    border: 0;
    text-transform: uppercase;
    font-style: italic;
    font-family: 'Passion One', cursive;
    float: left;
    padding: 0 60px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.gallery-button-group {
    display: inline-block;
    width: 100%;
    margin-bottom: 25px;
}

.gallery-button-group button:last-child {
    padding: 0;
}

.gallery-button-group button.button.checked,
.gallery-button-group button:hover {
    color: #ff0a78;
}

.gallery-button-group button.button.is-checked {
    color: #ff0883;
}

.gImg .circle-icon i {
    color: #fff;
    font-size: 12px;
    position: relative;
    border-radius: 50%;
    background-color: #ff0a78;
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 34px;
}

.circle-icon {
    visibility: hidden;
    width: 48px;
    height: 48px;
    background-color: rgba(255, 10, 120, 0.5);
    text-align: center;
    line-height: 48px;
    border-radius: 50%;
    position: absolute;
    bottom: 20px;
    right: -40px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    transform: skew(29.6deg);
    -webkit-transform: skew(29.6deg);
    -moz-transform: skew(29.6deg);
    -o-transform: skew(29.6deg);
}

.gImg:hover .circle-icon {
    visibility: visible;
    right: 40px;
}

.gallery-grid {
    display: inline-block;
    width: 100%;
}

.gallery-grid:after {
    content: '';
    display: block;
    clear: both;
}

.grid-item {
    float: left;
    width: 200px;
    height: 160px;
    margin-bottom: 30px;
}

.grid-item--width2 {
    width: 310px;
}

#gallery-box {
    padding-left: 50px;
}

.gImg {
    position: relative;
    -webkit-transform: skew(-29.6deg) translateZ(0) scale(1.0, 1.0);
    -moz-transform: skew(-29.6deg) translateZ(0) scale(1.0, 1.0);
    -o-transform: skew(-29.6deg) translateZ(0) scale(1.0, 1.0);
    transform: skew(-29.6deg) translateZ(0) scale(1.0, 1.0);
    /*padding: 0 20px 30px 0;*/
    overflow: hidden;
    display: inline-block;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.gImg .gImgpath {
    display: inline-block;
    width: 100%;
    height: 100%;
    transform: skew(29.6deg);
    -webkit-transform: skew(29.6deg) translateZ(0) scale(1.0, 1.0);
    -moz-transform: skew(29.6deg) translateZ(0) scale(1.0, 1.0);
    -o-transform: skew(29.6deg) translateZ(0) scale(1.0, 1.0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.gImg img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%;
    height: auto;
    max-width: inherit;
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1.0, 1.0);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1.0, 1.0);
    transform: translate(-50%, -50%) translateZ(0) scale(1.0, 1.0);
    -webkit-filter: grayscale(100%);
    filter: gray;
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    filter: grayscale(100%);
    -webkit-transition: filter 0.3s ease-in-out;
    -moz-transition: filter 0.3s ease-in-out;
    -ms-transition: filter 0.3s ease-in-out;
    transition: filter 0.3s ease-in-out;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.gImg:hover img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}

.fancybox-overlay,
#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
    background-image: none !important;
}

#mainBox .fancybox-overlay-fixed {
    background-color: rgba(31, 31, 31, 0.75);
    z-index: 99999;
}

#mainBox .fancybox-opened {
    z-index: 999999;
}


/* 10. Products css */

.products {
    position: relative;
    /*padding-bottom: 74px;*/
}

.products p {
    font-style: italic;
    color: #fbfbfb;
}
#bby {
font-size: 12px;
 text-align: center;
    margin-bottom: -15px;
    border-bottom-left-radius: 12px;
    font-family: Passion One;
    color: white;
    border-bottom-right-radius: 12px;
    background-color: #bb2166;






}
.procap {
    padding-right: 20%;
}

.products .owl-carousel .owl-item img {
    display: inline-block;
    width: 73%;
    margin: 80px 0 40px;
}

.item {
    padding: 0 15px;
    width: 327px;
    text-align: center;
}

.owl-carousel .item .element {
    margin-bottom: 85px;
}

.priceBox h4 {
    display: inline-block;
    font-size: 16px;
    color: #ff0a78;
    margin: 20.5px 0 !important;
}

.priceBox h4 del {
    color: #737373;
    margin-right: 30px;
}

.stars li {
    display: inline-block;
}

.stars li a {
    font-size: 20px;
    color: #ffd322;
}

.products .element h4 {
    margin-bottom: 12px;
}

.stars {
    margin-bottom: 4px;
}

.products .owl-carousel.owl-loaded {
    display: inline-block;
    width: 100%;
    padding-top: 56px;
}

.products .owl-nav .owl-prev,
.products .owl-nav .owl-next {
    display: block !important;
    position: absolute;
    top: -30px;
    font-size: 0;
    background-color: #201f1f;
    transform: skew(-29.6deg);
    -webkit-transform: skew(-29.6deg);
    -moz-transform: skew(-29.6deg);
    -o-transform: skew(-29.6deg);
    box-shadow: 0px 15px 20px -12px rgba(0, 0, 0, 0.5);
}

.products .owl-nav .owl-next {
    right: 0;
}

.products .owl-nav .owl-next:before {
    font-family: FontAwesome;
    content: "\f178";
    display: inline-block;
    font-size: 30px;
    color: #ffe402;
    line-height: normal;
    transform: skew(29.6deg);
    -webkit-transform: skew(29.6deg);
    -moz-transform: skew(29.6deg);
    -o-transform: skew(29.6deg);
    padding: 9px 15px;
}

.products .owl-nav .owl-prev {
    left: auto;
    right: 75px;
}

.products .owl-nav .owl-prev:before {
    font-family: FontAwesome;
    content: "\f177";
    display: inline-block;
    font-size: 30px;
    color: #ffe402;
    line-height: normal;
    transform: skew(29.6deg);
    -webkit-transform: skew(29.6deg);
    -moz-transform: skew(29.6deg);
    -o-transform: skew(29.6deg);
    padding: 9px 15px;
}

.products .fill-btn {
    width: 90%;
    transform: translate(0%, 0);
    -webkit-transform: translate(0%, 0);
    -moz-transform: translate(0%, 0);
    -ms-transform: translate(0%, 0);
    position: static;
    position: absolute;
    left: 3%;
    top: 0;
    line-height: normal;
    height: 50px;
    margin: 0 15px;
}

.products .fill-btn.circled {
    visibility: hidden;
    opacity: 0;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0.5;
    }
    50% {
        opacity: 5;
    }
    75% {
        opacity: 7.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0.5;
    }
    50% {
        opacity: 5;
    }
    75% {
        opacity: 7.5;
    }
    100% {
        opacity: 1;
    }
}

.products .element .fill-btn.circled {
    left: 58%;
}

.products .element .fill-btn.circled:last-child {
    right: 3%;
    left: auto;
}

.products .element:hover .fill-btn {
    width: 199px;
    float: left;
}

.products .element:hover .fill-btn.circled {
    width: 50px;
    visibility: visible;
    opacity: 1;
}

.products .element .buy-buttons {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    width: 100%;
    display: inline-block;
    height: 50px;
}


/* 11. Call-to-action css */

.call-to-action {
    position: relative;
    background-color: #4b00a8;
}

.call-to-action .actionBg {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    /*background: url('http://placehold.it/1920x436') no-repeat center;*/
    width: 100%;
    height: 100%;
}

.call-to-action .titleTop,
.call-to-action .sectionTitle {
    color: #fff;
}

.call-to-action p {
    color: #fee302;
    padding: 0;
    font-style: italic;
}

.call-to-action .fill-btn {
    position: static;
    transform: translate(0%, -100%);
    -webkit-transform: translate(0%, -100%);
    -moz-transform: translate(0%, -100%);
    -ms-transform: translate(0%, -100%);
    float: right;
}

.call-to-action .fill-btn:hover {
    background-color: #ffe402;
    box-shadow: 0px 10px 50px -10px rgb(254, 227, 1);
    color: #201f1f;
}


/* 12. Plans css */

.plans {
    position: relative;
}

.plan-top {
    display: inline-block;
    position: absolute;
    top: -3px;
    left: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ff0a78;
    z-index: 1;
}

.plan-top:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: #ff0a78;
    transform-origin: bottom left;
    -ms-transform: skew(-29.6deg, 0deg);
    -webkit-transform: skew(-29.6deg, 0deg);
    -moz-transform: skew(-29.6deg, 0deg);
    transform: skew(-29.6deg, 0deg);
}

.plan-top h5 {
    color: #fff;
    z-index: 1;
    position: relative;
    text-transform: uppercase;
    font-style: italic;
    font-family: 'Passion One', cursive;
    font-size: 20px;
    padding: 27px 22px;
}

.skewback:before {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: -80px;
    background: #ffe402;
    border-bottom: 3px solid #ffe402;
    border-right: 20px solid #ffe402;
    transform-origin: bottom left;
    -ms-transform: skew(30deg, 0deg);
    -webkit-transform: skew(30deg, 0deg);
    transform: skew(-29.6deg, 0deg);
}

.plan-side {
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 133px;
}

.skewback {
    background: #ffe402;
    width: 100%;
    height: 100%;
}

.skewback h2 {
    font-size: 80px;
    position: relative;
    text-transform: uppercase;
    font-family: 'Passion One', cursive;
    text-align: right;
    right: 25px;
    top: 32px;
}

.skewback h2 sub {
    font-size: 12px;
    bottom: 5px;
    left: 10px;
    letter-spacing: 0.28px;
}

.skewback h6 {
    position: absolute;
    left: -20px;
    font-weight: 600;
    text-transform: uppercase;
    bottom: 15px;
}

.plan-list {
    margin: 210px 0 28px;
    display: inline-block;
    width: 100%;
}

.plan-list li {
    font-size: 18px;
    color: #737373;
    font-style: italic;
    padding: 11px 0;
    border-bottom: 1px solid rgba(32, 31, 31, 0.10);
}

.plan-list li:last-child {
    border-bottom: 0;
}

.pricebox .element {
    margin-bottom: 27px;
}

.pricebox .midelement {
    background-color: #ff0a78;
}

.pricebox .midelement .plan-top,
.pricebox .midelement .plan-top:before {
    background-color: #201f1f;
}

.pricebox .midelement h5 {
    color: #ffe402;
}

.pricebox .midelement .skewback {
    background: #ffffff;
}

.pricebox .midelement .skewback:before {
    background: #ffffff;
    border-color: #ffffff;
}

.pricebox .midelement li {
    color: #ffffff;
}

.pricebox .midelement .fill-btn {
    background-color: #201f1f;
    box-shadow: 0px 10px 50px -10px rgb(32, 31, 31);
}

.pricebox .midelement .fill-btn:hover {
    background-color: #ffe402;
    box-shadow: 0px 10px 50px -10px rgb(255, 228, 2);
    color: #201f1f;
}

.midIcon {
    margin: 70px 0 30px;
    display: inline-block;
    width: 100%;
}

.midelement .plan-list {
    margin-top: 0;
}

.midelement .midIcon .fIcons {
    margin: 110px 0 14px;
}

.midelement .midIcon .fIcons:before {
    font-size: 170px;
    color: #fff;
}

.pricebox {
    margin: 60px 0;
}

.pricebox.midprice {
    margin: 0;
}


/* 13. Testimonial css */

.testimonial {
    position: relative;
    background-color: #201f1f;
}

.testimonial .testimonialBg {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0.05;
    background: url('http://placehold.it/1920x1141') no-repeat center;
    width: 100%;
    height: 100%;
}

.testimonial .titleTop,
.testimonial .sectionTitle {
    color: #fff;
    text-align: center;
    margin-left: 0;
}

.spacer {
    display: inline-block;
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    background-image: -moz-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0) 15%, rgb(255, 10, 120) 50%, rgba(255, 0, 0, 0) 85%, rgba(255, 0, 0, 0) 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 0, 0, 0)), color-stop(15%, rgba(255, 0, 0, 0)), color-stop(50%, rgb(255, 10, 120)), color-stop(85%, rgba(255, 0, 0, 0)), color-stop(100%, rgba(255, 0, 0, 0)));
    background-image: -webkit-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0) 15%, rgb(255, 10, 120) 50%, rgba(255, 0, 0, 0) 85%, rgba(255, 0, 0, 0) 100%);
    background-image: -o-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0) 15%, rgb(255, 10, 120) 50%, rgba(255, 0, 0, 0) 85%, rgba(255, 0, 0, 0) 100%);
    background-image: -ms-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0) 15%, rgb(255, 10, 120) 50%, rgba(255, 0, 0, 0) 85%, rgba(255, 0, 0, 0) 100%);
    background-image: linear-gradient(to right, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0) 15%, rgb(255, 10, 120) 50%, rgba(255, 0, 0, 0) 85%, rgba(255, 0, 0, 0) 100%);
}

.clientSlide .item {
    text-align: center;
}

.clientSlide .item p {
    color: #fff;
    font-style: italic;
    margin-top: 52px;
}

.clientSlide .item h4 {
    font-weight: 900;
    color: #ff0a78;
    text-transform: uppercase;
    font-style: italic;
    margin-top: 52px;
}

.clientSlide .item h5 {
    color: #737373;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 600;
}

.clientSlide .item img {
    width: auto;
    margin: 0 auto;
}

.clientSlide .owl-nav .owl-prev,
.clientSlide .owl-nav .owl-next {
    display: inline-block !important;
    font-size: 0;
    width: 60px;
    background-color: #ffe402;
    transform: skew(-29.6deg);
    -webkit-transform: skew(-29.6deg);
    -moz-transform: skew(-29.6deg);
    -o-transform: skew(-29.6deg);
    box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.5);
}

.clientSlide .owl-nav .owl-next:before {
    font-family: FontAwesome;
    content: "\f178";
    display: inline-block;
    font-size: 30px;
    color: #201f1f;
    line-height: normal;
    transform: skew(29.6deg);
    -webkit-transform: skew(29.6deg);
    -moz-transform: skew(29.6deg);
    -o-transform: skew(29.6deg);
    padding: 9px 15px;
}

.clientSlide .owl-nav .owl-prev {
    margin-right: 25px;
}

.clientSlide .owl-nav .owl-prev:before {
    font-family: FontAwesome;
    content: "\f177";
    display: inline-block;
    font-size: 30px;
    color: #201f1f;
    line-height: normal;
    transform: skew(29.6deg);
    -webkit-transform: skew(29.6deg);
    -moz-transform: skew(29.6deg);
    -o-transform: skew(29.6deg);
    padding: 9px 15px;
}

.clientSlide .owl-nav {
    text-align: center;
    margin-top: 60px;
}


/* 14. News css */

.news {
    position: relative;
}

.news.products .owl-carousel .owl-item img,
.hot-news figure {
    width: 100%;
    margin: 0 0 30px;
}

.news.products .owl-carousel .owl-item .news-comments img,
.hot-news .news-comments img,
.news-comments a img {
    width: auto;
    margin: 2px 6px 0 0;
    float: left;
}

.news .item h4 {
    color: #1e1e1e;
    font-weight: 900;
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 13px;
}

.news .item ul li {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 27px;
    font-size: 14px;
    color: #737373;
}

.news .item ul li a {
    color: #737373;
}

.news .item ul li a:hover {
    color: #ff0a78;
}

.news-comments a img {
    float: left;
}

.news-comments a {
    display: inline-block;
    float: left;
    margin-right: 18px;
    color: #737373;
    font-size: 12px;
    font-weight: bold;
}

.news-comments {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.news-fill-btn {
    text-align: center;
    margin-top: 47px;
}

.news-fill-btn .fill-btn {
    position: static;
    transform: translate(0%, 0%);
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    float: none;
    width: 285px;
    display: inline-block;
    margin: 0 auto;
    line-height: 50px;
    padding: 0 5px;
}

.news-fill-btn .fill-btn:hover {
    color: #fff !important;
}

.news .news-comments i.fa {
    font-size: 16px;
    margin-right: 5px;
}


/* 15. Footer css */

.footer {
    position: relative;
    background-color: #201f1f;
}

.footerBg {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0.13;
    background: url('http://placehold.it/1920x677') no-repeat top center;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.footer_logo {
    margin-bottom: 60px;
}

.form-Box input[type="text"],
.form-Box input[type="email"],
.form-Box input[type="passowrd"],
.form-Box textarea {
    width: 100%;
    height: 50px;
    border: 1px solid #737373;
    border-radius: 25px;
    background-color: transparent;
    padding: 5px 25px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #737373;
    font-style: italic;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
}

.form-Box textarea {
    height: 113px;
    padding: 15px 25px;
    resize: none;
}

.b_effect:focus,
.subscribe form input:focus {
    border-color: #ff0a78;
}

.form-Box .fill-btn {
    position: static;
    transform: translate(0%, 0);
    -webkit-transform: translate(0%, 0);
    -moz-transform: translate(0%, 0);
    -ms-transform: translate(0%, 0);
    width: 184px;
}

.form-Box .fill-btn:hover,
.subscribe form .fill-btn:hover {
    background-color: #ffe402;
    color: #201f1f;
    box-shadow: 0px 10px 50px -10px rgb(255, 228, 2);
}

.fTitle {
    font-weight: 900;
    color: #ffffff;
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 20px;
}

.fTitle span {
    color: #ff0a78;
}

.contact-address ul li,
.contact-address ul li a {
    color: #737373;
    font-size: 16px;
    font-style: italic;
    margin-bottom: 15px;
    line-height: 32px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
}

.contact-address {
    padding: 0 15%;
}

.contact-address ul li i {
    margin-right: 15px;
    display: inline-block;
    float: left;
    line-height: 32px;
}

.contact-address ul li span {
    display: table;
}

.contact-address ul li a:hover {
    color: #ff0a78;
}

.subscribe form {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 60px;
}

.subscribe form input {
    width: 100%;
    height: 50px;
    border: 1px solid #737373;
    border-radius: 25px;
    background-color: transparent;
    padding: 5px 215px 5px 25px;
    font-size: 14px;
    color: #737373;
    font-style: italic;
}

.subscribe form .fill-btn {
    bottom: auto;
    top: 0;
    right: 0;
    left: auto;
    transform: translate(0%, 0);
    -webkit-transform: translate(0%, 0);
    -moz-transform: translate(0%, 0);
    -ms-transform: translate(0%, 0);
    width: 209px;
}

.social-icons ul li {
    display: inline-block;
    float: left;
    color: #fff;
    text-align: center;
    line-height: 43px;
    position: relative;
    width: 43px;
    height: 43px;
    margin-right: 28px;
}

.social-icons ul li a {
    color: #fff;
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: 16px;
}

.social-icons ul li:before {
    content: "";
    width: 43px;
    height: 43px;
    display: inline-block;
    position: absolute;
    border: 1px solid #ff0a78;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
}

.social-icons ul li:last-child {
    margin: 0;
}

.social-icons ul li:hover:before {
    background: #ff0a78;
}


/* 16. Copyright css */

.copyright {
    padding: 51.5px 0;
    text-align: center;
}

.copyright h6 {
    font-size: 14px;
    font-style: italic;
    color: #737373;
}

.copyright h6 a {
    color: #ff0a78;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
}

.copyright h6 a:hover {
    color: #201f1f;
}


/* 17. back to top css */

.back-to-top {
    display: inline-block;
    height: 60px;
    width: 60px;
    position: fixed;
    bottom: -50px;
    right: 20px;
    z-index: 10;
    overflow: hidden;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    text-align: center;
    line-height: 60px;
}

.back-to-top:before {
    content: "";
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    background: #ff0a78;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    left: 10px;
    top: 10px;
}

.back-to-top:hover:before {
    background: #201f1f;
}

.back-to-top i {
    color: #fff;
    font-size: 16px;
    position: relative;
}

.back-to-top.cd-is-visible {
    visibility: visible;
    opacity: 1;
    bottom: 20px;
}

.back-to-top.cd-is-visible,
.back-to-top.cd-fade-out,
.no-touch .back-to-top:hover {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}


/* 18. News Page css */

.inner-pages {
    position: relative;
}

.inner-banner {
    overflow: hidden;
}

.news-banner {
    background-color: #201f1f;
    position: relative;
}

.news-banner:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('http://placehold.it/1920x1280') no-repeat fixed top center;
    opacity: 0.1;
}

.inner-banner .bannerText {
    left: 0;
    text-align: center;
    padding: 90px 0;
}

.inner-banner .bannerText h1 {
    color: #ffd322;
    text-transform: uppercase;
    font-style: italic;
    font-family: 'Passion One', cursive;
}

.inner-banner .bannerText ol {
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.inner-banner .bannerText ol .active {
    color: #fff;
    padding-left: 25px;
}

.inner-banner .bannerText ol li:nth-child(2) {
    padding-left: 25px;
}

.inner-banner .bannerText ol a {
    color: #ff0a78;
}

.inner-banner .bannerText ol li {
    font-style: italic;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 8px;
    position: relative;
}

.inner-banner .bannerText ol a:hover {
    color: #fed221;
}

.inner-banner .bannerText .breadcrumb>li+li:before {
    content: "\f111";
    display: inline-block;
    font-family: 'FontAwesome';
    position: absolute;
    top: 11px;
    left: 0;
    width: auto;
    height: auto;
    font-style: normal;
    font-size: 6px;
    color: #fff;
}

.mid-banner-news img {
    width: 100%;
}

.hot-news p {
    color: #737373;
    font-style: italic;
    margin-bottom: 30px;
}

.hot-news figure figcaption {
    background-color: #201f1f;
    color: #fed221;
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0px 5px 0 2px;
    z-index: 1;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
}

.hot-news figure figcaption span {
    margin-right: 8px;
}

.hot-news figure figcaption span:last-child {
    margin-right: 0;
}

.hot-news figure figcaption a {
    color: #fed221;
    text-decoration: underline;
}

.hot-news figure figcaption a:hover {
    color: #ff0a78;
}

.hot-news figure {
    position: relative;
}

.hot-news figure figcaption:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: -12px;
    background: #201f1f;
    transform-origin: bottom left;
    -ms-transform: skew(-29.6deg, 0deg);
    -webkit-transform: skew(-29.6deg, 0deg);
    -moz-transform: skew(-29.6deg, 0deg);
    transform: skew(-29.6deg, 0deg);
    z-index: -1;
}

.hot-news .news {
    border-bottom: 1px solid #d3d3d3;
}

.nlist-info {
    background-color: #fff;
    padding: 40px;
    display: inline-block;
    width: 100%;
}

.posted-by span {
    font-size: 11px;
    color: #1e1e1e;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 8px;
}

.posted-by span a {
    text-decoration: underline;
    color: #ff0a78;
}

.posted-by .badge {
    color: #fff;
    background-color: #ff0a78;
    padding: 4.5px 16px;
}

.nlist-info .nlist-title {
    font-weight: 900;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.posted-by {
    margin-bottom: 30px;
}

.nlist-info p {
    color: #737373;
    font-style: italic;
    margin-bottom: 30px;
}

.nlist-info p a,
.list-status span a,
.widget_recent_entries .bpost span a {
    text-decoration: underline;
    text-transform: uppercase;
    color: #ff0a78;
}

.nlist-info p a:hover,
.widget_recent_entries .bpost span a:hover {
    color: #201f1f;
}

.nlist-bottom .news-comments {
    width: auto;
    float: left;
}

.nlist-bottom .social {
    display: inline-block;
    float: right;
}

.nlist-bottom .social li {
    float: left;
    margin: 0 0 0 16px !important;
}

.nlist-bottom .social li a {
    color: #737373;
    font-size: 15px;
}

.nlist-info .posted-by span a:hover,
.nlist-info span a:hover {
    color: #201f1f;
}

.nlist-bottom .social li a:hover {
    color: #ff0a78;
}

.nlist-bottom .social li:first-child {
    margin: 0 !important;
}

.n-listing:last-child {
    margin-bottom: 30px;
}

.n-listing {
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
    margin-bottom: 60px;
}

.nlist-bottom {
    display: inline-block;
    width: 100%;
}

.list-status {
    text-align: center;
}

.list-status {
    background: url('http://placehold.it/1764x800') no-repeat fixed top center;
    position: relative;
}

.list-status:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #201f1f;
    opacity: 0.92;
}

.list-status .nlist-info {
    background-color: transparent;
    position: relative;
    z-index: 1;
}

.list-status .nlist-info figure {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto 20px;
    overflow: hidden;
}

.list-status .nlist-info figure img {
    border-radius: 50%;
    overflow: hidden;
}

.list-status .badge {
    float: none;
    width: auto;
    display: inline-block;
    background-color: #ff0a78;
    padding: 4.5px 16px;
}

.list-status span {
    width: 100%;
    display: block;
    color: #fff;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.list-status span a:hover {
    color: #fed221;
}

.list-status .nlist-info .nlist-title a {
    color: #fff;
}

.list-status .nlist-info .nlist-title a:hover {
    color: #ff0a78;
}

.list-link .nlist-title a {
    text-decoration: underline;
}

.n-listing.item,
.hot-news .item {
    padding: 0;
}

#owl-news-list .owl-item {
    opacity: 0;
}

#owl-news-list .owl-item.active {
    opacity: 1;
}

#owl-news-list .owl-stage-outer {
    overflow: visible;
}

#owl-news-list .news-comments a img {
    width: auto;
}

#owl-news-list .owl-controls {
    position: absolute;
    top: auto;
    bottom: 360px;
    right: 40px;
}

#owl-news-list .owl-next,
#owl-news-list .owl-prev {
    display: block !important;
    font-size: 0;
    background-color: #ffe402;
    transform: skew(-29.6deg);
    -webkit-transform: skew(-29.6deg);
    -moz-transform: skew(-29.6deg);
    -o-transform: skew(-29.6deg);
    box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.5);
    float: left;
}

#owl-news-list .owl-controls .owl-prev:before {
    font-family: FontAwesome;
    content: "\f177";
    display: inline-block;
    font-size: 30px;
    color: #201f1f;
    line-height: normal;
    transform: skew(29.6deg);
    -webkit-transform: skew(29.6deg);
    -moz-transform: skew(29.6deg);
    -o-transform: skew(29.6deg);
    padding: 9px 15px;
}

#owl-news-list .owl-controls .owl-next:before {
    font-family: FontAwesome;
    content: "\f178";
    display: inline-block;
    font-size: 30px;
    color: #201f1f;
    line-height: normal;
    transform: skew(29.6deg);
    -webkit-transform: skew(29.6deg);
    -moz-transform: skew(29.6deg);
    -o-transform: skew(29.6deg);
    padding: 9px 15px;
}

#owl-news-list .owl-controls .owl-prev {
    left: auto;
    right: 75px;
    margin-right: 20px;
}

.news-list-left {
    overflow: hidden;
}

.video-block,
.video-iframe {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

#work-video,
#work-video-frame {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}


/*sidebar*/

.widget {
    padding: 30px;
    background-color: #fff;
    margin-bottom: 60px;
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
}

.widget:last-child {
    margin-bottom: 0;
}

.sidebar .widget-title {
    font-size: 20px;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 25px;
}

.sidebar .widget_search .widget-title {
    color: #fff;
}

.widget_search form {
    position: relative;
}

.widget_search form input {
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 50px;
    padding: 5px 60px 5px 20px;
    font-size: 14px;
    color: #737373;
    font-style: italic;
}

.widget_search {
    background-color: #ff0a78;
}

.widget_search form button {
    background-color: #1e1e1e;
    border: 0;
    color: #fff;
    font-size: 24px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.widget_search form button:hover {
    background-color: #fed221;
}

.widget_calculator {
    background-color: #1e1e1e;
}

.widget_calculator .widget-title {
    color: #fff;
}

.calc_gender {
    color: #fff;
    display: inline-block;
    width: 100%;
    margin-bottom: 25px;
    padding-left: 23px;
}

.calc_gender input[type=radio] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.calc_gender label {
    line-height: 50px;
    float: left;
    color: #989898;
    text-transform: uppercase;
}

.calc_gender span label {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    font-size: 34px;
    cursor: pointer;
    margin: 0 0 0 30px;
}

input[type=radio].with-font ~ label:before {
    font-family: FontAwesome;
    display: inline-block;
    color: #535353;
    font-size: 34px;
}

input[type=radio].with-font#gender1 ~ label:before {
    content: "\f222";
}

input[type=radio].with-font#gender2 ~ label:before {
    content: "\f221";
}

input[type=radio].with-font:checked ~ label {
    background-color: #ff0a78;
}

input[type=radio].with-font:checked ~ label:before {
    color: #fff
}

input[type=radio].with-font:focus ~ label {
    background-color: #ff0a78;
}

.w-cal {
    width: 33.334%;
    float: left;
    padding: 0 15px;
    margin-bottom: 25px;
}

.w-cal input[type="text"],
.w-cal select {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    border: 0;
    border-radius: 50px;
    font-size: 14px;
    color: #737373;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.w-cal input[type="text"]::-webkit-input-placeholder {
    color: #737373;
}

.w-cal input[type="text"]:-moz-placeholder {
    color: #737373;
}

.w-cal input[type="text"]::-moz-placeholder {
    color: #737373;
}

.w-cal input[type="text"]:-ms-input-placeholder {
    color: #737373;
}

.w-cal:first-child {
    padding-left: 0;
}

.w-cal:last-of-type {
    padding-right: 0;
}

.calculate {
    display: inline-block;
    width: 100%;
}

.w-cal label {
    text-align: center;
    color: #989898;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 10px;
}

.w-cal:nth-child(2) {
    padding: 0 8px;
}

.calc-result label {
    width: 110px;
    background-color: #fed221;
    height: 50px;
    padding: 0 20px;
    border: 0;
    line-height: 50px;
    border-radius: 50px;
    font-size: 16px;
    color: #1f1e1f;
    text-align: center;
    font-weight: 900;
    font-style: italic;
    margin-right: 15px;
}

.calculate .fill-btn {
    position: static;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    width: 64%;
}

.calc-result {
    display: inline-block;
    float: left;
}

.calculate .fill-btn:hover {
    background-color: #fed221;
    box-shadow: 0px 10px 50px -10px rgb(254, 210, 33);
    color: #1e1e1d;
}

.calc-result span {
    display: block;
    color: #989898;
    font-style: italic;
    margin-top: 5px;
}

.widget_recent_entries img {
    display: inline-block;
    float: left;
    width: 100px;
    height: auto;
}

.widget_recent_entries .bpost {
    display: table;
    padding-left: 15px;
}

.widget_recent_entries .bpost h5 {
    text-transform: uppercase;
    color: #1e1e1e;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 11px;
    transition: .5s all;
    -webkit-transition: .5s all;
    -moz-transition: .5s all;
    -o-transition: .5s all;
    font-weight: 900;
}

.widget_recent_entries .bpost span {
    font-size: 11px;
    color: #1e1e1e;
    text-transform: uppercase;
    margin-right: 8px;
    font-weight: bold;
}

.widget_recent_entries ul li a:hover h5 {
    color: #ed804a;
}

.widget_recent_entries ul li {
    margin-bottom: 35px;
}

.widget_recent_entries ul li:last-child {
    margin-bottom: 0;
}

.widget .tagcloud a {
    padding: 5px 19px;
    display: inline-block;
    margin: 0 0 10px 5px;
    background-color: #ff0a78;
    color: #fff;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    border-radius: 50px;
    text-transform: uppercase;
    letter-spacing: 0.24px;
    font-style: italic;
    font-weight: bold;
}

.widget .tagcloud a:hover {
    background-color: #201f1f !important;
    color: #fff !important;
}


/* 19. News Details Page css */

blockquote {
    background-color: #f9f9f9;
    border-left: 3px solid #ff0a78;
    padding: 25px 30px;
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.nlist-info blockquote p,
blockquote p {
    font-size: 20px;
    color: #1e1e1e;
    font-style: italic;
    margin-bottom: 20px;
}

blockquote h6 {
    display: inline-block;
    float: right;
    font-size: 12px;
    text-transform: uppercase;
    color: #ff0a78;
    font-weight: 600;
    letter-spacing: 0.96px;
}

.n-list-author .nlist-top {
    display: inline-block;
    float: left;
    width: 200px;
    height: 100%;
    overflow: hidden;
}

.n-list-author .nlist-info {
    display: table;
    padding: 30px;
    width: auto;
}

.n-listing iframe,
.n-listing video,
.n-listing source {
    width: 100%;
    float: left;
    background-color: rgb(0, 0, 0);
}

.n-list-author h5 {
    text-transform: uppercase;
    font-weight: 900;
    color: #1e1e1e;
    float: left;
    display: inline-block;
    margin-right: 15px;
}

.n-list-author h6 {
    text-transform: uppercase;
    font-weight: 600;
    color: #1e1e1e;
    font-size: 11px;
    position: relative;
    top: -1px;
}

.n-list-author p {
    margin-top: 20px;
}

.n-list-author .social {
    float: none;
}

.n-list-author img {
    width: 100%;
}

.n-list-author {
    overflow: hidden;
    background-color: #fff;
}

.blog-comment-main {
    background-color: #fff;
    padding: 0px 40px;
}

.blog-comment {
    display: inline-block;
    width: 100%;
    padding-right: 20px;
    padding: 40px 20px 40px 0;
    vertical-align: top;
}

.blog-comment .comment-avtar {
    float: left;
    max-width: 110px;
    margin-right: 30px;
}

.comment-text p {
    font-style: italic;
    color: #737373;
}

.comment-text .blog-date {
    color: #737373;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
    font-style: normal;
}

.blog-date a {
    color: #1e1e1e;
    font-size: 16px;
    font-weight: 900;
    position: relative;
    margin-right: 20px;
}

.blog-date a:hover {
    color: #ff0a78;
}

.blog-title a {
    color: #1e1e1e;
    display: block;
    font-size: 14px;
    letter-spacing: 2px;
    margin: 5px 0 20px;
    text-transform: uppercase;
}

.blog-title a:hover {
    color: #ff0a78;
}

.blog-comment > .blog-comment {
    padding: 60px 0 0 140px;
}

.comment-text {
    position: relative;
    display: table;
}

.blog-date .comment-reply {
    float: right;
    font-size: 14px;
    color: #ff0a78;
    font-style: italic;
    font-weight: 600;
    margin: 0;
}

.blog-date .comment-reply:hover {
    color: #1e1e1e;
}

.blog-comment > .blog-comment .blog-comment {
    padding-left: 0;
}

.blog-comment-main > .blog-comment:last-child {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 60px;
}

.blog-comment-form-main {
    background-color: #fff;
    padding: 60px 40px;
}

.blog-comment-form .form-Box .fill-btn {
    width: 252px;
}

.inner-blog-section {
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
    margin-bottom: 26px;
}

.blog-date a:after {
    content: "\f111";
    display: inline-block;
    font-family: 'FontAwesome';
    position: absolute;
    top: -3px;
    right: -15px;
    width: auto;
    height: auto;
    font-style: normal;
    font-size: 4px;
    color: #737373;
}

.blog-date .comment-reply:after {
    content: "";
}


















.mobilebar-container {
    position: fixed;
    bottom: 15px;
    z-index: 12;
    left: 50%;
    transform: translateX(-50%);
}

.mobilebar-tab {
    height: 50px;
    max-width : 420px;
    width: 90vw;
    /*background: transparent linear-gradient(2deg, #bb2c6c 0%, #e5e5e5 100%) 0% 0% no-repeat padding-box;*/

background-color: #fdfdfd;
//box-shadow: 0 10px 6px 0 rgba(0, 0, 0, 0.175);
//border-radius: 6px;
    overflow: hidden;
//border: 10px solid #fff;
    display: flex;
    position: relative;
    flex-shrink: 0;
    bottom: 0px;
}

.mobilebar-tab-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% / 5);
    flex-shrink: 0;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    z-index: 2;
}

.mobilebar-tab-item.active {
//width: 105px;
//width: 16vw;
//width: calc(100% / 5);
//max-width: 105px;
}
#imgfooter{
    filter: blur(8px);
    -webkit-filter: blur(4px);
}
#resume {
    background-color: #3b97d3;
    box-shadow: 0px 0px 71px 10px #0960fe;;
    position: relative;
    transform: scale(1);
    animation: pulse 2s infinite;
    border-radius: 12px;
}
.mobilebar-tab img {
    width: 41px;
}
.mobilebar-tab-overlay {
    border-radius: 6px;
    background-color: #f0f0f0;
    height: 100%;
    width: calc(100% / 5);
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.3s;
}

.mobilebar-tab__icon {
    display: block;
    color: #F96972;
    transition-duration: 0.3s;
    line-height: 1;
}